<div [ngClass]="{'content-loading':isRefresh,'content-loaded':!isRefresh}">
    <mat-spinner *ngIf="isRefresh" diameter="25" strokeWidth="5"></mat-spinner>
</div>
<mat-toolbar class="display_large_screen">
    <mat-toolbar-row class="mat-toolbar-row-class global-header-menu bg-header-{{envConfig['oemPlatformAcronym']}} ">
        <div
            class="gbl-header-color-{{envConfig['oemPlatformAcronym']}} w-full flex items-center justify-between left-0 right-0 z-30">
            <div class="float-left flex min-w-34 w-3/12">
                <a [href]="envConfig['oemUrl']" target="_blank">
                    <img [src]="envConfig | hdrImgSrc" height="40px" width="175.61px" class="w-56"
                        [analyticTagging]="{'eventId':'globalNavLogoFn','srcCtrl':envConfig['oemName']}">
                </a>
            </div>
            <div class="float-right flex items-end w-9/12 small-devices">
                <div class="text-right w-auto ml-auto global-navigation-target-padding " id="global-navigation-target">
                    <app-mbf-search *ngIf="canLoadHeader" class="pad-right-px-19" [viewSearchBar]="false">
                    </app-mbf-search>
                    <button *ngIf="this.currentTab == 'MBF Home'" class="text-white cursor-pointer ml-8 actPadding"
                        mat-button matTooltip="Workspace Options" matTooltipClass="tooltip"
                        [matMenuTriggerFor]="workspaceOption"
                        [analyticTagging]="{'eventId':'secondaryBannerIconsFn','srcCtrl':'Workspace'}">
                        <i class="text-white zmdi zmdi-view-quilt font26px"></i>
                    </button>
                    <mat-menu #workspaceOption="matMenu" [overlapTrigger]="false"
                        class="disable_mat_menu pt-1 pl-8 dropdown !overflow-hidden mat-menu-item-remove-hover menuOptionsBackdrop">
                        <div id="workspaceOption" class="-mt-3 ml-0 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div class="pt-8"></div>
                        <span class="tracking-wider header-menu-title text-px-14">MBF HOME</span>
                        <div class="pt-1"></div>
                        <ng-container *ngIf="!workspaceLocked">
                            <a mat-menu-item class="text-color-black" *ngFor="let item of dashboardItems"
                                (click)="openDashboardDialog(item)"
                                [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}
                            </a>
                        </ng-container>
                        <ng-container *ngIf="workspaceLocked">
                            <div *ngFor="let item of dashboardItems" matTooltipPosition="above"
                                [matTooltipDisabled]="item.title == 'Reset home page to default'"
                                matTooltip="Workspace does not support function. Please change your workspace and try again.">
                                <a mat-menu-item class="text-color-black"
                                    [ngClass]="{'dashboard-disable':(item.title != 'Reset home page to default')}"
                                    [disabled]="item.title != 'Reset home page to default'"
                                    (click)="(item.title == 'Reset home page to default') && openDashboardDialog(item)"
                                    [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}
                                </a>
                            </div>
                        </ng-container>
                        <div *ngIf="communitiesItems.length > 0">
                            <div class="pt-8"></div>
                            <span class="tracking-wider header-menu-title text-px-14">COMMUNITIES</span>
                            <div class="pt-1"></div>
                            <a mat-menu-item class="text-color-black" *ngFor="let item of communitiesItems"
                                href="{{item.url}}" [target]="item.urlTarget"
                                [analyticTagging]="{'eventId':'secondaryNavDropDownClickFn','srcCtrl':['WORKSPACE',item.shortName]}">{{item.title}}</a>
                        </div>
                    </mat-menu>
                    <button #notificationTrigger="matMenuTrigger" class="text-white cursor-pointer actPadding"
                        mat-button matTooltip="Notifications" matTooltipClass="tooltip"
                        [matMenuTriggerFor]="notification" [disabled]="undismissedNotificationsAvailable"
                        [analyticTagging]="{'eventId':'secondaryBannerNotificationIconFn','srcCtrl':this}">
                        <i class="text-white zmdi zmdi-notifications font26px" [matBadge]=unreadNotifications
                            matBadgeColor="warn" matBadgeSize="small"
                            [matBadgeHidden]="!unreadNotificationsAvailable"></i>
                    </button>
                    <mat-menu #notification="matMenu" [overlapTrigger]="false"
                        class="pt-1 notification-dropdown !overflow-hidden icecube-menu-mr menuOptionsBackdrop">
                        <div id="notification" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div class="pt-3"></div>
                        <span class="notification-menu-title font-sans uppercase">Notifications</span>
                        <div class="pt-1"></div>
                        <div class="dropdownStyle">
                            <div class="notificationLine" mat-menu-item *ngFor="let item of notifications">
                                <div class="float-left open-notification-div"
                                    (click)="openNotificationDetailsDialog('Details', item)"
                                    [analyticTagging]="{'eventId':'notificationFlyout','srcCtrl':this}">
                                    <div class="notificationHeading text-base"
                                        [ngClass]="{'read-Heading': item.messageRead }">
                                        <span class="dot" *ngIf="!item.messageRead" [ngClass]="{
                                            'unplanned-dot' : item.messageType === 'UNPLANNED_OUTAGE',
                                            'planned-dot' : item.messageType === 'PLANNED_OUTAGE',
                                            'update-dot' : (item.messageType === 'USER_NOTIFICATION' || item.messageType === 'INFORMATIONAL'),
                                            'training-dot' : (item.messageType === 'TRAINING'),
                                            'action-req-dot' : (item.messageType === 'ACTION_REQUIRED')
                                            }"></span> {{memoizeGetmessageType | getNotificationHeader:item.messageType
                                        }}
                                    </div>
                                    <div class="notificationTitle" [ngClass]="{'read-Title': item.messageRead }">
                                        <span matTooltipPosition="right"
                                            matTooltip="{{item.messageTitle}}">{{dislayTitle(item.messageTitle)}}</span>
                                    </div>
                                </div>
                                <div>
                                    <button *ngIf="item.messageType === 'USER_NOTIFICATION'" mat-button
                                        matTooltip="Click to delete the notification" matTooltipClass="tooltip"
                                        (click)="deleteNotification(item)">
                                        <mat-icon class="close-icon-notification">close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="no-notification" *ngIf="notifications.length === 0">
                                <span class="item.messageRead">No active notifications or alerts.</span>
                            </div>
                        </div>
                        <div *ngIf="unreadMessages.length > 0" class="clearUpdates" (click)="clearUpdates()">
                            MARK ALL AS READ
                        </div>
                    </mat-menu>
                    <button id="need-help-target" class="text-white cursor-pointer actPadding" mat-button
                        matTooltip="Help" matTooltipClass="tooltip" [matMenuTriggerFor]="help"
                        [analyticTagging]="{'eventId':'secondaryBannerIconsFn','srcCtrl':'Help'}">
                        <i class="text-white zmdi zmdi-help font26px"></i>
                    </button>
                    <mat-menu #help="matMenu" [overlapTrigger]="false"
                        class="help-menu-mr pl-8 dropdown !overflow-hidden mat-menu-item-remove-hover menuOptionsBackdrop">
                        <div id="help" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div class="pt-3"></div>
                        <span class="header-menu-title">Help</span>
                        <div class="pt-1"></div>
                        <span *ngFor="let item of helpItems">
                            <span *ngIf="item.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
                            </span>
                            <ng-template #MyFielderviceLink>
                                <a *ngIf="!(user && user.airlineCode && user.airlineCode == 'TBC')" mat-menu-item
                                    class="" (click)="urlClicked(item)" href="javascript:void(0);"
                                    [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                            </ng-template>
                            <ng-template #otherLinks>
                                <span
                                    *ngIf="item.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                                <ng-template #accessAdminLink>
                                    <a class="" *ngIf="accessAdministratorFlag" mat-menu-item (click)="urlClicked(item)"
                                        href="javascript:void(0);"
                                        [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                                </ng-template>
                                <ng-template #noAccessAdminLinks>
                                    <span
                                        *ngIf="item.title=='Product and Data Access (Access Summary)';then accessSummaryLink else noAccessSummaryLinks"></span>
                                    <ng-template #accessSummaryLink>
                                        <a class="" mat-menu-item (click)="urlClicked(item)" href="javascript:void(0);"
                                            [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                                    </ng-template>
                                    <ng-template #noAccessSummaryLinks>
                                        <a mat-menu-item class=""
                                            (click)="item.title=='Tutorial' ? openTutorialDialog('Tutorial') : urlClicked(item)"
                                            href="javascript:void(0);"
                                            [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                                    </ng-template>
                                </ng-template>
                            </ng-template>

                        </span>
                    </mat-menu>

                    <button [analyticTagging]="{'eventId':'globalNavIceCubeTrayFn','srcCtrl':'Ice Cube Tray'}"
                        class="text-white cursor-pointer appsPadding actPadding globalNavIceCub" mat-button
                        matTooltip="Links" [matMenuTriggerFor]="icecube">
                        <i class="text-white zmdi zmdi-apps font26px"></i>
                    </button>
                    <mat-menu #icecube="matMenu" xPosition="before" [overlapTrigger]="false"
                        class="pl-8 pt-1 dropdown !overflow-hidden mat-menu-item-remove-hover icecube-menu-mr menuOptionsBackdrop">
                        <div id="icecube" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div *ngFor="let menu of secondaryMenu; let first = first">
                            <div class="{{first ? '' : 'pt-2'}}"></div>
                            <div class="header-menu-title pb-1">{{menu.title}}</div>
                            <ng-container *ngFor="let submenuItem of menu.submenu">
                                <a *ngIf="(memoizeGetBuildUrl | headerBuildUrl:submenuItem) else notLink; let url"
                                    mat-menu-item
                                    class="{{submenuItem.title === 'MyBoeingFleet' && mbfHome ? 'text-gray-500' : ''}}"
                                    href="{{url}}" [target]="submenuItem.target"
                                    [analyticTagging]="{'eventId':'globalNavTrayDropDownExitLinkFn','srcCtrl':[menu.title,submenuItem.title]}">{{submenuItem.title}}
                                </a>
                                <ng-template #notLink>
                                    <span mat-menu-item disabled class="text-gray-500">
                                        {{submenuItem.title}}
                                    </span>
                                </ng-template>
                            </ng-container>
                        </div>
                    </mat-menu>
                    <button [analyticTagging]="{'eventId':'globalNavAccountFn','srcCtrl':'Account'}"
                        class="text-white cursor-pointer actPadding" mat-button matTooltip="Account"
                        [matMenuTriggerFor]="account">
                        <i class="text-white zmdi zmdi-account-circle font26px"></i>
                    </button>
                    <mat-menu #account="matMenu" xPosition="before" [overlapTrigger]="false"
                        class="pt-1 account-dropdown !overflow-hidden mat-menu-item-remove-hover account-menu-mr menuOptionsBackdrop">
                        <div id="account" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div class="pt-4 pl-12 mb-8 truncate text-left max-w-full relative">
                            <div class="flex-row flex-wrap pt-8">
                                <div class="text-2xl font-bold text-account-header h-12 truncate">{{user.firstName}}
                                    {{user.lastName}}
                                </div>
                                <div class="text-xl font-bold text-gray-700 pt-2 truncate">{{user.emailAddress}}</div>
                            </div>
                            <div class="!pt-3.5"></div>
                            <a mat-menu-item href={{yourAccountLink}} target="_blank"
                                class="text-2xl h-16i text-submenu c-black"
                                [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'Your Account'}">
                                My Account Settings
                            </a>
                        </div>
                        <a mat-menu-item disabled class="filler"></a>
                        <a mat-menu-item (click)="openLogoutModal()"
                            class="text-2xl ml-8 logout-link remove-item-hover uppercase"
                            [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'LOG OUT'}">
                            Log out
                        </a>
                    </mat-menu>
                    
                    <mat-slide-toggle class="pr-1 ml-3 text-white" color="primary" [(ngModel)]="darkMode" (ngModelChange)="changeMode(darkMode)">
                        <mat-icon *ngIf="!darkMode" class="themeIcon text-white" [inline]="true" matTooltip="Dark Mode" svgIcon="light_mode"></mat-icon>
                        <mat-icon *ngIf="darkMode" class="themeIcon text-white" [inline]="true" matTooltip="Light Mode" svgIcon="dark_mode"></mat-icon>
                    </mat-slide-toggle>
                                      
                </div>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="display_side_Menu">
    <mat-toolbar-row
        class="mat-toolbar-row-class p-0 global-header-menu bg-header-{{envConfig[ 'oemPlatformAcronym']}} ">
        <div
            class="gbl-header-color-{{envConfig[ 'oemPlatformAcronym']}} w-full flex justify-between items-center left-0 right-0 z-30">
            <div class="ml-6 mt-1 outline-none display_style">
                <a [href]="envConfig[ 'oemUrl'] " target="_blank ">
                    <img [src]="envConfig | hdrImgSrc " height="40px" width="175.61px" class="w-56 "
                        [analyticTagging]="{ 'eventId': 'globalNavLogoFn', 'srcCtrl':envConfig[ 'oemName']} ">
                </a>

            </div>

        </div>
        <app-mbf-search class="w-full" [viewSearchBar]="false"
            (showSearchEvent)="updateShowSearchValue($event)"></app-mbf-search>
        <button mat-icon-button class="nav-menu" (click)="openOrCloseSideNav() ">
            <span *ngIf="!showClose"><mat-icon class="mr-8 text-px-30 white_color">menu</mat-icon></span>
            <span *ngIf="showClose"><i class="zmdi zmdi-close text-px-30 white_color"></i></span>
        </button>
        <mat-slide-toggle class="pr-1 ml-3 text-white" color="primary" [(ngModel)]="darkMode" (ngModelChange)="changeMode(darkMode)">
            <mat-icon *ngIf="!darkMode" class="themeIcon text-white" [inline]="true" matTooltip="Dark Mode" svgIcon="light_mode"></mat-icon>
            <mat-icon *ngIf="darkMode" class="themeIcon text-white" [inline]="true" matTooltip="Light Mode" svgIcon="dark_mode"></mat-icon>
        </mat-slide-toggle>
    </mat-toolbar-row>
</mat-toolbar>