<div #insideClick class="search-filed-wrapper no-elevation inline-block" [formGroup]="headerGroup"
    [ngClass]="{'w-full':searchDisplayBar==true}" (click)="enableSearchClick(true)">
    <mat-toolbar class="search-block search-height" (click)="enableSearchClick(true)">
        <mat-toolbar-row id="global-search-target" class="text-px-16 search-min-width p-0 c-blue"
            *ngIf="!searchDisplayBar">
            <div class="searchbar-desktopandtab">
                <div id="mat-cat-large" class="searchCategory">
                    <mat-form-field class="form-field-class">
                        <mat-select #lgMatSelect="matSelect" class="select-value-class" disableOptionCentering
                            formControlName="selectedCat" (selectionChange)="onCatChange($event)"
                            panelClass="mat-cat-dropdown display_large_screen_cat">
                            <div #insideClick>
                                <mat-option value="">{{'All' | uppercase}}</mat-option>
                                <mat-option *ngFor="let category of searchCategories | categoryListByUserAccess:user"
                                    value="{{category.id}}">
                                    <span>{{category.label | uppercase}}</span>
                                </mat-option>
                            </div>
                        </mat-select>

                    </mat-form-field>
                </div>
                <div class="searchInputText">
                    <input matInput #searchInput maxLength="80" formControlName="searchText" autocomplete="off"
                        id="searchInput" (keypress)="hasFocus=true;menuTrigger.openMenu();enableSearchIcons();"
                        (keyup.backspace)="hasFocus=true;menuTrigger.openMenu();enableSearchIcons();"
                        (paste)="searchClickFunc($event)" (click)="advsearchFilter=false"
                        (keyup.enter)="goToSearchPage(null)" type="text" placeholder="TYPE TO SEARCH"
                        class="search-control search-input-padding c-black outline-none">

                    <div class="searchandclose">
                        <button matTooltip="Clear search" *ngIf="!searchDisplayBar && showSearchClearIcons"
                            (click)="disableClearSearchIcons();" class="close-button" mat-icon-button>
                            <i class="zmdi zmdi-close search-click text-px-24"></i>
                        </button>
                        <button *ngIf="!searchDisplayBar" (click)="goToSearchPage(null)" class="search-button"
                            mat-icon-button matTooltip="Search">
                            <i class="zmdi zmdi-search search-click text-px-24 pb-4"></i>
                        </button>
                    </div>
                    <div #searchMenu class="inline-block w-0" #menuTrigger="matMenuTrigger"
                        (menuOpened)="advFilterMenuOpened()" [matMenuTriggerFor]="advsrchFilter">&nbsp;
                    </div>

                </div>
                <mat-menu #advsrchFilter="matMenu" xPosition="before" yPosition="below" [hasBackdrop]="false"
                    [overlapTrigger]="false" class="search-dropdown mat-menu-panel-class">
                    <ng-container *ngIf="!advsearchFilter" #insideClick>
                        <ng-container *ngIf="isLoading">
                            <mat-option class="line-height-reset search-option-padding">
                                <div>
                                    <div class="pr-3">
                                        <span>
                                            <span>
                                                Loading...
                                            </span>
                                            <mat-spinner class="float-right leading-none" diameter="25"></mat-spinner>
                                        </span>
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>

                        <ng-container *ngIf="!isLoading">
                            <mat-option *ngFor="let searchResult of searchResults;let i = index"
                                [value]="headerGroup.controls['searchText'].value" (mouseover)="highlightFunction()"
                                class="text-px-14 search-highlight-hover search-opt-wrap line-height-reset search-option-padding"
                                (click)="goToSearchPage(searchResult)"
                                [ngClass]="{'highlight-first-option':i==0 && highlightValue == false}">
                                <div class="search-opt-inner-wrap"
                                    *ngIf="searchResult.description.lastIndexOf('-') as DashLastIndex">
                                    <div class="items-center">
                                        <span class="search-results-title-label">{{ searchResult.ridentifier !== 'App
                                            Name Long' ?
                                            searchResult.ridentifier :
                                            (searchResult.appType === 'Application' ? 'Application' : 'Dashboard')
                                            }}</span>
                                        <br *ngIf="searchResult.ridentifier">
                                        <span class="with-out-open">
                                            <label class="mr2"
                                                [innerHTML]="(DashLastIndex>0?searchResult.description.substring(0, DashLastIndex):searchResult.description)  | highlightText:headerGroup.controls['searchText'].value | safe:'html'"></label>
                                            <label *ngIf="searchResult.appType==='Application'"
                                                [analyticTagging]="{'eventId':'appsClickFromSearchHeader','srcCtrl':searchResult}"
                                                class="uppercase font-semibold with-open"
                                                (click)="$event.stopPropagation();goToApplicationPage(searchResult);">
                                                Open
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>
                    </ng-container>

                </mat-menu>
            </div>
        </mat-toolbar-row>

        <!-- mobile changes starts here -->
        <mat-toolbar-row id="matsearchbar" class="text-px-12 p-0 search-min-width-mobile" *ngIf="searchDisplayBar">
            <div class="searchbar-mobile">
                <div class="searchCategory-mobile">
                    <mat-form-field class="form-field-class-mobile">
                        <mat-select #lgMatSelect="matSelect" class="select-value-class-mobile" disableOptionCentering
                            formControlName="selectedCat" (selectionChange)="onCatChange($event)"
                            panelClass="mat-cat-dropdown display_large_screen_cat">
                            <div #insideClick>
                                <mat-option value="">{{'All' | uppercase}}</mat-option>
                                <mat-option *ngFor="let category of searchCategories | categoryListByUserAccess:user"
                                    value="{{category.id}}">
                                    <span>{{category.label | uppercase}}</span>
                                </mat-option>
                            </div>
                        </mat-select>

                    </mat-form-field>
                </div>
                <div class="searchInputText-mobile">
                    <input matInput #searchInput maxLength="80" formControlName="searchText" autocomplete="off"
                        id="searchInput" (keypress)="hasFocus=true;menuTrigger.openMenu();enableSearchIcons();"
                        (keyup.backspace)="hasFocus=true;menuTrigger.openMenu();enableSearchIcons();"
                        (paste)="searchClickFunc($event)" (click)="advsearchFilter=false"
                        (keyup.enter)="goToSearchPage(null)" type="text" placeholder="TYPE TO SEARCH"
                        class="search-control c-black outline-none pt5">
                    <button matTooltip="Clear search" *ngIf="searchDisplayBar && showSearchClearIcons"
                        (click)="disableClearSearchIcons();" class="close-button-mobile" mat-icon-button>
                        <i class="zmdi zmdi-close search-click text-px-24"></i>
                    </button>
                    <button *ngIf="searchDisplayBar" (click)="goToSearchPage(null)" class="search-button-mobile"
                        mat-icon-button matTooltip="Search">
                        <i class="zmdi zmdi-search search-click text-px-24 pb-4"></i>
                    </button>
                    <div #searchMenu class="inline-block w-0" #menuTrigger="matMenuTrigger"
                        (menuOpened)="advFilterMenuOpened()" [matMenuTriggerFor]="advsrchFilter">&nbsp;
                    </div>

                </div>
                <mat-menu #advsrchFilter="matMenu" xPosition="before" yPosition="below" [hasBackdrop]="false"
                    [overlapTrigger]="false" class="search-dropdown mat-menu-panel-class-mobile">
                    <ng-container *ngIf="!advsearchFilter" #insideClick>
                        <ng-container *ngIf="isLoading">
                            <mat-option class="line-height-reset search-option-padding">
                                <div>
                                    <div class="pr-3">
                                        <span>
                                            <span>
                                                Loading...
                                            </span>
                                            <mat-spinner class="float-right leading-none" diameter="25"></mat-spinner>
                                        </span>
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>

                        <ng-container *ngIf="!isLoading">
                            <mat-option *ngFor="let searchResult of searchResults;let i = index"
                                [value]="headerGroup.controls['searchText'].value" (mouseover)="highlightFunction()"
                                class="text-px-14 search-highlight-hover search-opt-wrap line-height-reset search-option-padding"
                                (click)="goToSearchPage(searchResult)"
                                [ngClass]="{'highlight-first-option':i==0 && highlightValue == false}">
                                <div class="search-opt-inner-wrap"
                                    *ngIf="searchResult.description.lastIndexOf('-') as DashLastIndex">
                                    <div class="items-center">
                                        <span class="search-results-title-label">{{ searchResult.ridentifier !== 'App
                                            Name Long' ?
                                            searchResult.ridentifier :
                                            (searchResult.appType === 'Application' ? 'Application' : 'Dashboard')
                                            }}</span>
                                        <br *ngIf="searchResult.ridentifier">
                                        <span class="with-out-open">
                                            <label class="mr2"
                                                [innerHTML]="(DashLastIndex>0?searchResult.description.substring(0, DashLastIndex):searchResult.description)  | highlightText:headerGroup.controls['searchText'].value | safe:'html'"></label>
                                            <label *ngIf="searchResult.appType==='Application'"
                                                [analyticTagging]="{'eventId':'appsClickFromSearchHeader','srcCtrl':searchResult}"
                                                class="uppercase font-semibold with-open"
                                                (click)="$event.stopPropagation();goToApplicationPage(searchResult);">
                                                Open
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>
                    </ng-container>
                </mat-menu>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>