import { Directive, ElementRef, Input, NgZone } from '@angular/core';

@Directive({
  selector: '[appTinymceRichTextContent]'
})
export class TinymceRichTextContentDirective {
  @Input() appTinymceRichTextContent: string;
  @Input() scrollHeight: number;
  currentIframe: any;
  constructor(el: ElementRef, private ngZone: NgZone) {
    el.nativeElement.id = Math.random();
    this.currentIframe = el;
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: any) {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        try {
          if (this.currentIframe.nativeElement.contentWindow) {
            const value = this.stringToHTML(this.appTinymceRichTextContent);
            const id = this.currentIframe.nativeElement.id;
            let previewContent = '';
            previewContent = '<!DOCTYPE html><html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><script>window.onload = function() {window.parent.postMessage({height:document.body.scrollHeight,id:"' + id + '",scrollHeight:' + this.scrollHeight + '},"*")};window.onresize = function() {window.parent.postMessage({height:document.body.scrollHeight,id:"' + id + '",scrollHeight:' + this.scrollHeight + '},"*")};</script><link rel="stylesheet" type="text/css"  href="./tinymce/skins/ui/oxide/content.min.css"><link rel="stylesheet" type="text/css"  href="./tinymce/skins/content/default/content.min.css"><link href="./assets/lib/material-design-iconic-font/css/noto-sans-font.css" rel="stylesheet"></head><body class="mce-content-body" contenteditable="false"><div class="tiny_main_wrapper">' + (value ? value : '') + '</div></body></html>';
            const iframe = this.currentIframe.nativeElement;
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(previewContent);
            iframe.contentWindow.document.close();
          }
        }
        catch (ex) {

        }

      });
    });
  }
  private stringToHTML(str: string): string {
    if (str) {
      const searchRegExp = new RegExp('="contentLibrary/|="/contentLibrary/|="../contentLibrary/', 'igm');
      str = str.replace(searchRegExp, '="/portal_ng/contentLibrary/');
      return str;
    }
    return '';
  }
}
