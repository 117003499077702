import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilityService {

  constructor(private appConfigService: AppConfigService) { }

  public getSiteServerRelativeUrl(): string {
    const endpoint = this.appConfigService.getPortalUIPath('');
    return endpoint;
  }

  public getNgAbsoluteUrl(): string {
    const endpoint = this.appConfigService.getPortalNgPath() + '/';
    return endpoint;
  }

  public getTinyMCEConfig(height): any {
    const tinyConfig = {
      base_url: this.getSiteServerRelativeUrl() + '/tinymce',
      suffix: '.min',
      relative_urls: true,
      document_base_url: this.getNgAbsoluteUrl(),
      browser_spellcheck: true,
      plugins: [
        'autoresize advlist autolink autosave link image lists charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'save table directionality template codesample'],
      external_plugins: {},
      add_unload_trigger: false,
      autosave_ask_before_unload: false,
      toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect formatselect | cut copy paste pastetext | | searchreplace | bullist numlist | outdent indent blockquote | | link unlink anchor image media code | insertdatetime | forecolor backcolor | table | hr removeformat | subscript superscript | charmap | ltr rtl | visualchars visualblocks nonbreaking template pagebreak restoredraft | insertfile insertimage codesample | fullscreen  preview print',
      menubar: false,
      max_height: height,
      elementpath: false,
      toolbar_items_size: 'small',
      branding: false,
      toolbar_mode: 'sliding',
      statusbar: false,
      paste_data_images: true
    };
    return tinyConfig;
  }

  public getTinyMCEConfigReleaseNotes(height): any {
    const tinyConfig = {
      base_url: this.getSiteServerRelativeUrl() + '/tinymce',
      suffix: '.min',
      relative_urls: true,
      document_base_url: this.getNgAbsoluteUrl(),
      browser_spellcheck: true,
      plugins: [
        'autoresize advlist autolink autosave link image lists charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'save table  directionality template codesample'],
      external_plugins: {},
      add_unload_trigger: false,
      autosave_ask_before_unload: false,
      toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect formatselect | cut copy paste pastetext | | searchreplace | bullist numlist | outdent indent blockquote | | link unlink anchor image media code | insertdatetime | forecolor backcolor | table | hr removeformat | subscript superscript | charmap | ltr rtl | visualchars visualblocks nonbreaking template pagebreak restoredraft | insertfile insertimage codesample | fullscreen  preview print',
      menubar: false,
      min_height: height,
      elementpath: false,
      toolbar_items_size: 'small',
      branding: false,
      toolbar_mode: 'sliding',
      statusbar: false,
      paste_data_images: true
    };
    return tinyConfig;
  }

  public getTinyMCEConfigProdAdmin(height): any {
    const tinyConfig = {
      base_url: this.getSiteServerRelativeUrl() + '/tinymce',
      suffix: '.min',
      relative_urls: true,
      document_base_url: this.getNgAbsoluteUrl(),
      browser_spellcheck: true,
      plugins: [
        'autoresize advlist autolink autosave link image lists charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'save table directionality template codesample'],
      external_plugins: {},
      add_unload_trigger: false,
      autosave_ask_before_unload: false,
      toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | cut copy paste pastetext | searchreplace | bullist numlist | fontselect fontsizeselect formatselect | outdent indent blockquote | | link unlink anchor image media code | insertdatetime | forecolor backcolor | table | hr removeformat | subscript superscript | charmap | ltr rtl | visualchars visualblocks nonbreaking template pagebreak restoredraft | insertfile insertimage codesample | fullscreen  preview print',
      menubar: false,
      min_height: height,
      elementpath: false,
      toolbar_items_size: 'small',
      branding: false,
      toolbar_mode: 'sliding',
      statusbar: false,
      paste_data_images: true
    };
    return tinyConfig;
  }


  public resizeIframe() {
    const myEventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const myEventListener = window[myEventMethod];
    const myEventMessage = myEventMethod === 'attachEvent' ? 'onmessage' : 'message';
    myEventListener(myEventMessage, this.onMessage.bind(this), false);
  }

  onMessage(e) {
    try {
      if (e.data.height > e.data.scrollHeight) {
        document.getElementById(e.data.id).style.height = e.data.scrollHeight + 'px';
      } else if (e.data.height === parseInt(e.data.height, 10)) {
        document.getElementById(e.data.id).style.height = e.data.height + 40 + 'px';
      }
      if (document.getElementById(e.data.id) !== null) {
        document.getElementById(e.data.id).style.visibility = 'visible';
      }
    }
    catch (ex) { }
  }

  public repairMatTab(tabs: any) {
    if (tabs) {
      try {
        tabs.realignInkBar();
        if (tabs._tabHeader) {
          tabs._tabHeader.updatePagination();
        }
      } catch (ex) { }
    }
  }

  /**
   * Method to calculate the scrollbar width
   * @returns Scrollbar width
   */
  public getScrollbarWidth() {
    const inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';
    const outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);
    document.body.appendChild(outer);
    const w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    if (w1 === w2) {
      w2 = outer.clientWidth;
    }
    document.body.removeChild(outer);
    return (w1 - w2);
  }

}
