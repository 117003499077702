<div [ngClass]="{'darkMode': darkTheme }">
    <mat-sidenav-container class="sticky" [hasBackdrop]="false">
        <mat-sidenav *ngIf="!isSolo && isMobile && isOtherPage && !isClafSupport" [(opened)]="opened" #sidenav
            disableClose [fixedInViewport]="true" class="sidenav-width pos border-bottom-css">
            <mat-nav-list>
                <ng-container *ngIf="isMobile">
                    <app-header-mbf-sidenav></app-header-mbf-sidenav>
                </ng-container>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>

            <div scroll width="992" widthDefault="true" offset="70" class="min-h-calc" *ngIf="isMobile">
                <ng-container *ngIf="!isSolo && !isClafSupport">
                    <header *ngIf="isOtherPage" class="relative z-5 posStick">
                        <app-header-mbf [darkMode]="darkTheme" (darkModeEmitter)="changeTheme($event)"></app-header-mbf>
                    </header>
                </ng-container>
                <div *ngIf="isMobile" class="mx-auto w-full">
                    <router-outlet (activate)="changeOfRoutes()"></router-outlet>
                </div>
            </div>
            <div id="fullBodyContent" ng-class="relative min-h-994">
                <div scroll width="992" widthDefault="true" offset="70" class="min-h-calc" *ngIf="!isMobile">
                    <ng-container *ngIf="!isSolo && !isClafSupport">
                        <header *ngIf="isOtherPage" class="relative z-5 posStick">
                            <app-header-mbf [darkMode]="darkTheme" (darkModeEmitter)="changeTheme($event)"></app-header-mbf>
                        </header>
                    </ng-container>
                    <div *ngIf="!isMobile" class="mx-auto w-full displaypattern">
                        <div #sidenav class="w-nav colorNav min-h-calc">
                            <app-header-mbf-sidenav class="posStickNav"></app-header-mbf-sidenav>
                        </div>

                        <div class="w-dash pt4 ptoverlap">
                            <router-outlet (activate)="changeOfRoutes()"></router-outlet>
                        </div>

                    </div>
                </div>
                <div *ngIf='isGdprBannerAvailable && isOtherPage'>
                    <app-cookie-banner [isFeatureActive]='isGdprBannerAvailable'></app-cookie-banner>
                </div>
                <div #backToTop class="flex justify-between" *ngIf="!isSolo && !isClafSupport">
                    <div *ngIf="!isMobile" class="empty"></div>
                    <div [ngClass]="{'w-full': isMobile }">
                        <app-back-to-top></app-back-to-top>
                    </div>

                </div>
            </div>
            <footer *ngIf="!isSolo && isOtherPage && !isClafSupport">
                <app-footer-mbf></app-footer-mbf>
            </footer>
        </mat-sidenav-content>

    </mat-sidenav-container>
</div>